import {getFormProps, getInputProps, useForm} from '@conform-to/react'
import {getZodConstraint, parseWithZod} from '@conform-to/zod'
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from '@remix-run/node'
import {Form, Link, useActionData, useSearchParams} from '@remix-run/react'
import {useTranslation} from "react-i18next";
import {HoneypotInputs} from 'remix-utils/honeypot/react'
import {z} from 'zod'
import MagicLinkEmail from "#app/components/emails/magic-link.server.tsx";
import {GeneralErrorBoundary} from '#app/components/error-boundary.tsx'
import {CheckboxField, ErrorList, Field} from '#app/components/forms.tsx'
import {ButtonLink} from "#app/components/ui/button-link.tsx";
import {StatusButton} from '#app/components/ui/status-button.tsx'
import {prepareVerification} from "#app/routes/_auth+/verify.server.ts";
import {login, requireAnonymous} from '#app/utils/auth.server.ts'
import {prisma} from "#app/utils/db.server.ts";
import {sendEmail} from "#app/utils/email.server.ts";
import {checkHoneypot} from '#app/utils/honeypot.server.ts'
import {i18next} from "#app/utils/i18next.server.ts";
import {isDemoMode, useIsPending} from '#app/utils/misc.tsx'
import {redirectWithToast} from "#app/utils/toast.server.ts";
import {EmailSchema, PasswordSchema} from '#app/utils/user-validation.ts'
import {userName} from "#app/utils/user.ts";
import {handleNewSession} from './login.server.ts'

const LoginFormSchema = z.object({
  intent: z.literal('password'),
  email: EmailSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional(),
  remember: z.boolean().optional(),
})

const MagicLinkFormSchema = z.object({
  intent: z.literal('magic-link'),
  email: EmailSchema,
  redirectTo: z.string().optional(),
})

const FormSchema = z.discriminatedUnion('intent', [
  LoginFormSchema,
  MagicLinkFormSchema,
])

export {meta} from '#app/root.tsx'

export async function loader({request}: LoaderFunctionArgs) {
  await requireAnonymous(request)
  const t = await i18next.getFixedT(request, "common")
  return json({
    title: t('login.title'),
  })
}

export async function action({request}: ActionFunctionArgs) {
  await requireAnonymous(request)
  const t = await i18next.getFixedT(request, "common")
  const locale = await i18next.getLocale(request);
  const formData = await request.formData()
  checkHoneypot(formData)
  const submission = await parseWithZod(formData, {
    schema: FormSchema.transform(async (data, ctx) => {
      if (data.intent === 'password') {
        const session = await login(data)
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('auth.invalidEmailOrPassword'),
          })
          return z.NEVER
        }

        return {...data, session}
      } else {
        const user = await prisma.user.findUnique({
          where: {email: data.email, deletedAt: null, emailVerified: {not: null}},
          select: {id: true, email: true, firstName: true, lastName: true},
        })
        // we prepare a verification if the user exists
        if (user) {
          const {verifyUrl, otp} = await prepareVerification({
            period: 10 * 60,
            request,
            type: 'magic-link',
            target: user.email,
          })

          await sendEmail({
            to: user.email,
            subject: t('magicLink.emailSubject'),
            react: (
              <MagicLinkEmail t={t} linkUrl={verifyUrl.toString()} otp={otp} locale={locale} userName={userName(user)}/>
            ),
          })
        }
        // it doesn't matter if the user exists or not, we don't want to leak this information
        return {...data, session: null}
      }
    }),
    async: true,
  })

  // @ts-expect-error we know the intent is here, I don't know what TS is complaining about
  if (submission.value?.intent === "magic-link" && submission.status === "success") {
    return redirectWithToast(`/verify?type=magic-link&target=${submission.value.email}`, {
      description: t('magicLink.mailSent', {email: submission.value.email}),
    })
  }

  if (submission.status !== 'success' || !submission.value.session) {
    return json(
      {result: submission.reply({hideFields: ['password']})},
      {status: submission.status === 'error' ? 400 : 200},
    )
  }

  const {session, remember, redirectTo} = submission.value

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
  })
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>()
  const isPending = useIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  const method = searchParams.get('method') ?? (isDemoMode() ? 'magic-link' : 'password')
  const {t} = useTranslation()

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getZodConstraint(method !== 'password' ? MagicLinkFormSchema : LoginFormSchema),
    defaultValue: {redirectTo},
    lastResult: actionData?.result,
    onValidate({formData}) {
      return parseWithZod(formData, {schema: method !== 'password' ? MagicLinkFormSchema : LoginFormSchema})
    },
    shouldRevalidate: 'onBlur',
  })

  return (
    <div className="flex-1 flex flex-col justify-center pb-32 pt-20">
      <div className="mx-auto w-full max-w-md">
        <h1 className="text-h3 text-center mb-8">{t('login.headline')}</h1>
        <div className="mx-auto w-full max-w-md px-8">
          <Form method="POST" {...getFormProps(form)} className="space-y-4">
            <HoneypotInputs/>
            <Field
              labelProps={{children: t('login.email')}}
              inputProps={{
                ...getInputProps(fields.email, {type: 'email'}),
                autoFocus: true,
                className: 'lowercase',
                autoComplete: 'email',
              }}
              errors={fields.email.errors}
            />

            {method === "password" && <>
                <Field
                    labelProps={{children: t('login.password')}}
                    inputProps={{
                      ...getInputProps(fields.password, {
                        type: 'password',
                      }),
                      autoComplete: 'current-password',
                    }}
                    errors={fields.password.errors}
                />

                <div className="flex items-center justify-between mb-4">
                    <CheckboxField
                        labelProps={{
                          htmlFor: fields.remember.id,
                          children: t('login.rememberMe'),
                        }}
                        buttonProps={getInputProps(fields.remember, {
                          type: 'checkbox',
                        })}
                        errors={fields.remember.errors}
                    />
                    <div>
                        <Link
                            to="/forgot-password"
                            className="text-body-xs font-semibold"
                        >
                          {t('login.forgotPassword')}
                        </Link>
                    </div>
                </div>
            </>}

            <input
              {...getInputProps(fields.redirectTo, {type: 'hidden'})}
            />
            <ErrorList errors={form.errors} id={form.errorId}/>

            <div className="flex flex-col items-center gap-6 pt-3">
              <StatusButton
                className="w-full"
                status={isPending ? 'pending' : form.status ?? 'idle'}
                type="submit"
                disabled={isPending}
                variant="brand"
                name="intent"
                value={method}
              >
                {method === "password" ? t('login.submit') : t('login.sendMagicLink')}
              </StatusButton>
              {method === "password" ? (
                <ButtonLink to="/login?method=magic-link" variant="link" size="sm">
                  {t('login.useMagicLink')}
                </ButtonLink>
              ) : (
                <ButtonLink to="/login?method=password" variant="link" size="sm">
                  {t('login.usePassword')}
                </ButtonLink>
              )}
              {isDemoMode() && (
                <div className="text-center mt-6 pt-12 border-t">
                  <h2 className="text-h5 mb-6">Du hast noch keinen Demo-Account?</h2>
                  <ButtonLink to="/demo-signup" variant="yellow">
                    Registriere dich hier
                  </ButtonLink>
                </div>
              )}
            </div>
          </Form>
          {/*<div className="mt-10">*/}
          {/*  <div className="relative">*/}
          {/*    <div className="absolute inset-0 flex items-center" aria-hidden="true">*/}
          {/*      <div className="w-full border-t border-gray-200"></div>*/}
          {/*    </div>*/}
          {/*    <div className="relative flex justify-center text-sm font-medium leading-6">*/}
          {/*      <span className="bg-white px-6 text-gray-900">{t('or')}</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<ul className="mt-5 flex flex-col gap-5 py-3">*/}
          {/*  {providerNames.map(providerName => (*/}
          {/*    <li key={providerName}>*/}
          {/*      <ProviderConnectionForm*/}
          {/*        type="Login"*/}
          {/*        providerName={providerName}*/}
          {/*        redirectTo={redirectTo}*/}
          {/*      />*/}
          {/*    </li>*/}
          {/*  ))}*/}
          {/*</ul>*/}
        </div>
      </div>
    </div>
  )
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary/>
}
